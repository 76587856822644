<template>
  <div class="container form-section" v-if="user">
    <div class="row">
      <div class="col-12">
        <h2 class="section-title">Contact</h2>
        <div class="section-body">
          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="titre">Titre</label>
              <input type="text" class="form-control" name="titre"
                v-model="user.title" :class="validation_class_for('title')"/>
              <div class="invalid-feedback">
                {{first_error_on_attribute('title')}}
              </div>
            </div>
            <div class="form-group col-md-5">
              <label for="ownerName">Nom</label>
              <input type="text" class="form-control" name="ownerName"
                v-model="user.ownerName" :class="validation_class_for('ownerName')"/>
              <div class="invalid-feedback">
                {{first_error_on_attribute('ownerName')}}
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="email">Email</label>
              <input type="text" class="form-control" name="email"
                v-model="user.email" :class="validation_class_for('email')"/>
              <div class="invalid-feedback">
                {{first_error_on_attribute('email')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <div class="section-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="contactFirstName">Contact à la maison</label>
              <input type="text" class="form-control" name="contactFirstName"
                v-model="user.contactFirstName" :class="validation_class_for('contactFirstName')"/>
              <div class="invalid-feedback">
                {{first_error_on_attribute('contactFirstName')}}
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="ownerPhone">Téléphone</label>
              <input type="text" class="form-control" name="ownerPhone"
                v-model="user.ownerPhone" :class="validation_class_for('ownerPhone')"/>
              <div class="invalid-feedback">
                {{first_error_on_attribute('ownerPhone')}}
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="addressDetails">Notes de livraison</label>
              <textarea name="addressDetails" v-model="user.addressDetails" :class="validation_class_for('addressDetails')"
                placeholder="Décrivez votre adresse exact et donnez des informations aidant à vous livrer"
                class="form-input bg-white text-gray-700 w-100 appearance-none border rounded-r focus:outline-none focus:border-blue-500" />
              <div class="invalid-feedback">
                {{first_error_on_attribute('addressDetails')}}
              </div>
            </div>
          </div>
          <div class="form-group text-center">
            <button @click="save()" class="btn btn-lg btn-secondary margin-top-30">Sauvegarder</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <div class="section-body">
          <p class="p-1">
            Pour changer votre lieu de livraison, veuillez nous contacter par téléphone
          </p>
          <p class="p-2">
            Nous sommes à votre disposition du Lundi au Vendredi de 9h à 17h.
          </p>
          <p class="title-3">78 480 54 54</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from './packs/services'

export default {
  props: ["userId"],
  data: function () {
    return {
      user: null,
      errors: []
    }
  },
  async mounted() {
    let result = await service.getUser(this.userId)
    this.user = result;
  },
  computed: {
  },
  methods: {
    async save() {
      try {
        this.user = await service.updateUser(this.user);
        this.errors = []
      } catch (err) {
        if(err.response.status == 422) {
          this.errors = err.response.data
        }
      }
    },

    validation_class_for(field_name) {
      if(this.errors) {
        let error = this.errors[field_name]
        return error ? 'is-invalid' : ''
      }
      return ''
    },

    first_error_on_attribute(field_name) {
      if(this.errors && this.errors[field_name]) {
        let firstError = this.errors[field_name][0]
        return firstError
      }
      return null
    }
  }
}
</script>

<style scoped>

.form-group.required .control-label:after {
  content:"*";
  color:red;
  margin-left: 3px;
}

.form-group .control-label {
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: 14px;
}

</style>
