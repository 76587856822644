<template>
  <div class="cart" v-if="cartNotEmpty">
    <div class="cart-header" @click="toggleCart()">
      Mon Panier 
      <span class="ic-md" v-show="showContent" style="font-size: 0.8em; color: #000;">
        <i class="fas fa-chevron-up"></i>
      </span> 
      <span class="ic-md" v-show="!showContent" style="font-size: 0.8em; color: #000;">
        <i class="fas fa-chevron-down"></i>
      </span>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="cartNotEmpty" v-show="showContent" class="cart-content">
        <div class="row cart-items">
          <div class="col-auto">
            <cart-item v-for="item in productsToValidate" :key="item.id" :item="item" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import service from './packs/services'

export default {
  data: function () {
    return {
      shakeEffect: "",
      productsToValidate: [],
      cart: null,
      showContent: false,
      temporary: true,
      loading: false
    }
  },
  mounted() {
    this.refresh()
  },

  computed: {

    cartNotEmpty() {
      return this.cart && this.productsToValidate.length > 0
    },

    cartTotal() {
      let total = 0
      this.productsToValidate.forEach(item => {
        total += item.quantity * item.product.price
      });
      return total
    },
  },

  methods: {

    toggleCart() {
      this.showContent = !this.showContent
    },

    async refresh() {
      this.loading = true
      this.cart = await service.getTCartContent()
      this.temporary = this.cart.temporary
      this.productsToValidate = this.cart.order_items.filter(p => this.temporary || !p.validated)
      this.loading = false
    },

    /**
     * Method validate
     */
    async validate(){
  
    }
  }
}
</script>

<style lang='scss' scoped>

.cart {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: 0;
  top: 65px;
  z-index: 50
}

.cart-header {
  background-color: #F4F3F0;
  display: flex;
  text-align: center;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-family: Recoleta;
  font-size: 16px;
  font-weight: bold;
  .ic-md {
    margin-top: 5px;
    margin-left: 5px;
  }
}

.cart-content {
  padding: 10px 10px;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color:#F4F3F0;
  z-index: 100;
}

.cart-items {
  height: 60%;
  overflow-y: auto;
}

.cart-action {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 10%;
}

.cart-total {
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: bold;
  height: 10%;
  .amount {
    font-size: 18px;
  }
  .currency {
    font-size: 18px;
    margin-left: 2px;
  }
}

.badge {
  padding: 0px;
  position: absolute;
  right: -10px;
  top: 10px;
  font-size: 10px;
  font-weight: 400;
  font-family: Montserrat;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.badge-warning {
  color: #FFF;
  background-color: #E97834;
}

.card {
  width: auto;
  height: 102px;
}

.card-body {
  padding: 10px 10px;
}

.card-body-title {
  height: 60px;
}

.card-title {
  margin-bottom: 5px;
}

.card-text-price {
  font-size: 10px;
}

.cart-item {
  margin-bottom: 10px;
  background-color:#fff;
}

.cart-item img {
  width: 100%;
  height: auto;
  min-height: 100px;
  object-fit: cover;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.amount {
  font-family: 'Montserrat'; 
  font-size: 8px;
}

.currency {
  font-family: 'Montserrat';
  font-size: 5px;
}


</style>
