<template>
  <div>
    <div class="map-indication">Trouvez votre adresse en cherchant via la barre de recherche puis glisser le marqueur</div>
    <div class="map-container">
      <div class="map-hover" v-show="mapHover">
        <div class="container">
          <div class="row justify-content-center mt-5">
            <button class="btn btn-lg btn-secondary" @click="ignoreStep()">Passer cette étape</button>
          </div>
          <div class="row justify-content-center mt-2">
            <button class="btn btn-lg btn-primary" @click="chooseLocalization()">Choisir une localisation</button>
          </div>
          <div class="row justify-content-center mt-2">
            <button class="btn btn-lg btn-primary" @click="shareCurrentPosition()">Partager ma position</button>
          </div>
        </div>
      </div>
      <div v-show="mapOpened" class="google-map" ref="googleMap"></div>
      <div v-show="mapOpened" class="input-group mb-3 input-search">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <i class="fas fa-bars" />
          </span>
        </div>
        <input v-show="placeSearchInitialized" class="form-control" ref="placeSearch" placeholder="Chercher votre adresse" />
      </div>
    </div>
    <div class="form-group d-flex justify-content-center">
      <button v-show="state == 'selected'" @click="ignoreStep()" type="button" class="btn btn-lg btn-primary" >Terminer</button>
    </div>
    <div class="form-group d-flex justify-content-center">
      <button v-show="state == 'not-selected'" @click="resetMap()" type="button" class="btn btn-lg btn-primary" >Retour</button>
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import service from './packs/services'
import zoneHelper from './packs/zone_helper'
import Vue from 'vue/dist/vue.esm'
import EventBus from './packs/eventBus'
import locationIndicator from 'images/location_ok.svg'

export default {
  props:['user'],
  data: function () {
    return {
      state: 'init',
      mapHover: true,
      mapOpened: true,
      placeSearchInitialized: false,
      map: null,
      placeSearch: null,
      marker: null,
      infowindow: null,
      zones: [],
      zonePrecise: false
    }
  },
  async mounted() {
    this.zones = await service.getZones()
    this.zones = this.zones.map(zone => {
      zone.formattedLocationPoints = zone.location_points.map(locationPoint =>	
        ({
          lat: locationPoint.lat,
          lng: locationPoint.lng
        }));
      return zone;
    });
    const googleMapLoader = new Loader({
      apiKey: 'AIzaSyBlmUqHY3MeJbIHUsJlV1Ll8aEhPwTkgRU',
      version: 'weekly',
      libraries: ['places', 'geometry']
    })
    await googleMapLoader.load()
    this.initializeMap()

    EventBus.$on('LocateMeClicked', function() {
      if(!this.zonePrecise) {
        this.$swal({
          icon: 'warning',
          cancelButtonText: 'OK',
          cancelButtonColor: '#2A6336',
          title: "Placez-vous plus précisément sur la carte.",
          text: "Zoomez à l'aide du bouton + et cliquez sur votre lieu de livraison exact.",
          showCancelButton: true,
          showConfirmButton: false
        });
      } else {
        let location = {
          lat: this.marker.position.lat(),
          lng: this.marker.position.lng()
        }
        let locationZone = zoneHelper.findLocationZone(location, this.zones, this.map)
        if(locationZone) {
          this.user.ZoneId = locationZone.id
          this.user.lat = this.marker.position.lat()
          this.user.lng = this.marker.position.lng()
          this.state = 'selected'
          EventBus.$emit("ZoneFound", locationZone)
        }else {
          this.$swal({
            icon: 'warning',
            cancelButtonText: 'OK',
            cancelButtonColor: '#2A6336',
            title: "Hors Zone",
            text: "Vous vous êtes placés dans une zone où nous ne livrons pas encore",
            showCancelButton: true,
            showConfirmButton: false
          });
        }
      }
      
    }.bind(this))
    
  },
  computed: {
  },
  methods: {

    /** Configure popup */
    configurePopup(){
      const contentString =
        '<div class="map-popup">' +
        ' <div v-show="!zoneFound" class="map-title">Localiser ma maison</div>'+ 
        ' <div v-show="!zoneFound" class="map-body">'+
        '   <button @click="locateMe" type="button" class="btn btn-primary">Valider</button>'+
        ' </div>'+ 
        ' <div v-show="zoneFound" class="map-body">'+
        '   <div class="map-day">Position validée</div>'+
        '   <div><img :src="locationIndicator" /></div>'+
        ' </div>'+ 
        '</div>'
      let infoWindowButton = Vue.extend({
        data() {
          return {
            zoneName: null,
            zoneFound:false,
            locationIndicator: locationIndicator
          }
        },
        template: contentString,
        created() {
          EventBus.$on('ZoneFound', function(z) {
            if(z) {
              this.zoneName = zoneHelper.zoneDayToFrench(z.day)
              this.zoneFound = true
            }else {
              this.zoneName = null
              this.zoneFound = false
            }
            
          }.bind(this))
        },
        methods : {
          locateMe() {
            EventBus.$emit("LocateMeClicked", {});
          }
        }
      })
      this.infowindow = new google.maps.InfoWindow({
        content: (new infoWindowButton().$mount()).$el
      })
    },

    showMap(){
      this.mapOpened = true
    },

    /** Manage marker bounce */
    toggleBounce() {
      if (this.marker.getAnimation() !== null) {
        this.marker.setAnimation(null);
      } else {
        this.marker.setAnimation(google.maps.Animation.BOUNCE);
      }
      this.infowindow.open(this.map, this.marker);
    },

    /** Initialize Map */
    initializeMap() {
      let _self = this
      this.configurePopup()
      const mapContainer = this.$refs.googleMap
      const options = {
        mapTypeControl: false,
				center: {
          lat: 14.6945917,
          lng: -17.4659374
        },
				zoom: 12
			}
      this.map = new google.maps.Map(
        mapContainer, options
      )
      const placeSearchContainer = this.$refs.placeSearch
      this.placeSearch = new google.maps.places.Autocomplete(placeSearchContainer)
      this.placeSearchInitialized = true
      this.placeSearch.addListener("place_changed", () => {
        _self.zonePrecise = false
        const place = this.placeSearch.getPlace()
        if(place && place.geometry) {
          let location = place.geometry.location
          let currentLocation = {
            lat: location.lat(),
            lng: location.lng()
          }
          this.marker = new google.maps.Marker({
            position: currentLocation,
            map: this.map,
            draggable: true,
            animation: google.maps.Animation.DROP,
          })
          _self.toggleBounce()
          this.marker.addListener("click", _self.toggleBounce);
          google.maps.event.addListener(this.marker, 'dragend', function(evt){
            _self.toggleBounce()
            _self.zonePrecise = true
            this.state = 'not-selected'
            EventBus.$emit("ZoneFound", null);
          });
          this.map.setCenter(currentLocation)
          this.map.setZoom(16)
          this.state = 'not-selected'
        }
      });

      this.map.addListener("click", (e) => {
        let currentLocation = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          }
        if(!this.marker) {
          this.marker = new google.maps.Marker({
            position: currentLocation,
            map: this.map,
            draggable: true,
            animation: google.maps.Animation.DROP,
          })
          _self.toggleBounce()
          this.marker.addListener("click", _self.toggleBounce);
          google.maps.event.addListener(this.marker, 'dragend', function(evt){
            _self.toggleBounce()
            _self.zonePrecise = true
            this.state = 'not-selected'
            EventBus.$emit("ZoneFound", null);
          });
          this.map.setCenter(currentLocation)
          this.map.setZoom(16)
        }
        this.marker.setPosition(currentLocation)
        this.map.panTo(e.latLng);
        _self.toggleBounce()
        _self.zonePrecise = true
        this.state = 'not-selected'
        EventBus.$emit("ZoneFound", null)
      });
      
    },

    resetMap() {
      this.infowindow.close()
      this.state = 'init'
      this.mapHover = true
    },

    ignoreStep() {
      window.location.href = '/account'
    },

    chooseLocalization() {
      this.mapHover = false
    },

    shareCurrentPosition() {
      this.mapHover = false
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.zonePrecise = true
            let currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            this.marker = new google.maps.Marker({
              position: currentLocation,
              map: this.map,
              draggable: true,
              animation: google.maps.Animation.DROP,
            })
            this.toggleBounce()
            this.marker.addListener("click", this.toggleBounce);
            google.maps.event.addListener(this.marker, 'dragend', function(evt){
              this.toggleBounce()
              this.zonePrecise = true
              EventBus.$emit("ZoneFound", null);
              this.state = 'not-selected'
            });
            this.map.setCenter(currentLocation)
            this.map.setZoom(16)
            this.state = 'not-selected'
          },
          () => {
            //handleLocationError(true, infoWindow, map.getCenter()!);
          }
        );
      } else {
        // Browser doesn't support Geolocation
        //handleLocationError(false, infoWindow, map.getCenter()!);
      }
    }

  }
}
</script>

<style scoped>

  .map-indication {
    color: #E97834;
    font-size: 12px
  }

  .map-hover {
    z-index: 10;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
  }

  .map-container {
    position: relative;
  }
  .google-map {
    width: 100%;
    min-height: 500px;
  }

  .input-search {
    position: absolute;
    width: 80%;
    top: 10px;
    left: 10px;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    height: 35px;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .input-search .input-group-text {
    background-color: #fff;
    border: none;
  }

  .input-search input {
    border: none;
    font-family: 'Montserrat';
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    height: 35px;
  }
  .input-search input:focus {
    box-shadow: none;
  }
</style>
