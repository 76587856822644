<template>
  <div class="row p-4">
    <div class="col-12 mb-5 title-1 text-center">
      Notifications
    </div>

    <div class="col-12" >
      <div class="row form-group mb-3 mt-3">
        <div class="col-10 form-label">Lorem ipsum dolor sit amet.</div>
        <div class="col-2">
          <toggle-button :value="false" color="#2A6336" :labels="true"/>
        </div>
      </div>
      <hr/>
      <div class="row form-group mb-3 mt-3">
        <div class="col-10 form-label">Lorem ipsum dolor sit amet.</div>
        <div class="col-2">
          <toggle-button :value="false" color="#2A6336" :labels="true"/>
        </div>
      </div>
      <hr/>
      <div class="row form-group mb-3 mt-3">
        <div class="col-10 form-label">Lorem ipsum dolor sit amet.</div>
        <div class="col-2">
          <toggle-button :value="false" color="#2A6336" :labels="true"/>
        </div>
      </div>
      <hr/>
      <div class="row form-group mb-3 mt-3">
        <div class="col-10 form-label">Lorem ipsum dolor sit amet.</div>
        <div class="col-2">
          <toggle-button :value="false" color="#2A6336" :labels="true"/>
        </div>
      </div>
      <hr/>
      <div class="row form-group mb-3 mt-3">
        <div class="col-10 form-label">Lorem ipsum dolor sit amet.</div>
        <div class="col-2">
          <toggle-button :value="false" color="#2A6336" :labels="true"/>
        </div>
      </div>
      <hr/>
      <div class="row form-group mb-3 mt-3">
        <div class="col-10 form-label">Lorem ipsum dolor sit amet.</div>
        <div class="col-2">
          <toggle-button :value="false" color="#2A6336" :labels="true"/>
        </div>
      </div>
      <hr/>
    </div>
  </div>
</template>

<script>
import service from './packs/services'
import moment from 'moment'

export default {
  props: ["userId"],
  data: function () {
    return {
      user: null,
      masterOrder: null,
      newDeliveryDate: null,
      deliveryStatus: null
    }
  },
  mounted() {
    return service.getUser(this.userId)
      .then(result => {
        this.user = result.account;
        this.masterOrder = result.master_order;
        this.deliveryStatus = this.deliveryStatuses.find(delivery => delivery.value === this.user.deliveryStatus);
        this.newDeliveryDate = moment(this.masterOrder.date).toDate();
      })
  },
  computed: {
    deliveryStatuses: function () {
      return [{
        value: "weekly",
        label: "Toutes les semaines"
      }, {
        value: "biweekly",
        label: "Toutes les deux semaines"
      }]
    }
  },
  methods: {

    updateSettings() {
      service.updateDeliverySettings(this.user.id, this.deliveryStatus.value, this.newDeliveryDate)
        .then(result => {
          console.log('successfully saved')
        });
    },
  }
}
</script>

<style scoped>
.form-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 35px;
}

</style>
