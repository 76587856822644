<template>
  <div v-if="cart" class="container">
    <div class="row justify-content-center mt-5">
      <a href="/users/sign_in" class="btn btn-lg btn-primary mr-2">Connectez-vous</a>
      <a href="/account/signup" class="btn btn-lg btn-primary ml-2">Créez un compte</a>
    </div>

    <!-- MY CART -->

    <div v-if="cart && cart.order_items.length > 0" class="row p-2 justify-content-center">
      <div class="accordion" id="accordionUnvalidated">
        <div class="card">
          <div class="card-header section-cart-products" id="headingUnvalidated">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseUnvalidated" aria-expanded="true" aria-controls="collapseUnvalidated">
                Les produits séléctionnés
              </button>
            </h2>
          </div>

          <div id="collapseUnvalidated" class="collapse show" aria-labelledby="headingUnvalidated" data-parent="#accordionUnvalidated">
            <div class="card-body">
              <div class="d-flex flex-col flex-wrap justify-content-around">
                <div class="product-container" v-for="item in cartBoxes" v-bind:key="item.id">
                  <h-cart-box-item :key="item.id" :box="item.box" :quantity="item.quantity" :oneShot="item.oneShot" />
                </div>
                <div class="product-container"
                  v-for="item in cartProducts"
                  v-bind:key="item.id"
                >
                  <h-cart-item :item="item" :likes="likes" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import eventBus from './packs/eventBus'
import service from './packs/services'
import wave from './packs/wave'

export default {
  props: ["userId"],

  data: function () {
    return {
      user: null,
      cart: null,
      boxes: [],
      likes: null
    }
  },
  async mounted() {
    this.refresh()

  },

  computed: {
    cartProducts() {
      return this.cart.order_items.filter(item => !item.panier)
    },

    cartBoxes() {
      return this.cart.order_items.filter(item => item.panier)
    },

    orderProducts() {
      return this.order.order_items.filter(item => item.type === 'product')
    },

    /** init boxes */
    orderBoxes() {
      
      let orderedBoxes = []
      this.boxes.forEach(box => {
        let occurrence = this.order.order_items.find(oi => oi.type === 'box' && oi.box_id == box.id)
        if(occurrence) {          
          let productBox = box.box_items.find(b => occurrence.product.id == b.productId)
          box.quantity = occurrence.quantity / productBox.quantity
          box.oneShot = occurrence.oneShot
          orderedBoxes.push(box)
        }
      });
      return orderedBoxes
    }

  },
  methods: {

    async refresh() {
      this.boxes = await service.getBoxes()
      this.cart = await service.getTCartContent()
      this.likes = await service.getLikes()
    },

  }
}
</script>

<style lang='scss' scoped>

.product-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

.accordion{
  width: 100%;
  .card {
    height: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
  }
  .card-header {
    position: relative;
    background: #FFFFFF;
    padding: .5rem .75rem;
    button {
      display: flex;
      align-items: center;
      &:after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
        box-sizing: border-box;
      } 
      &[aria-expanded="true"]:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg);
      }  
    }
  }

  .card-body {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
  }

  .card button,
  .card button:hover,
  .card button:active,
  .card button:focus {
    font-family: Recoleta;
    font-size: 14px;
    font-weight: 500;
    color: #D6403F;
    text-decoration: none;
    box-shadow: none;
  }
}

#main {
  overflow: hidden;
  #main-content {
    overflow: auto;
  }
  .overlay {
    display: block;
    background-color: #000;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0.5;
    z-index: 1072;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.section-payment-expanded {
  z-index: 1082;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
}

.row-payment {
  margin-left: 0px;
  margin-right: 0px;
  padding: 15px 25px 15px 15px;
  font-family: Montserrat;
  font-size: 13px;
}

.row-payment > div {
  padding-left: 2px;
  padding-right: 2px;
}

.row-recap {
  background: #F4F3F0;
}

.section-payment {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.16), 0 -1px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.16), 0 -1px 5px 0 rgba(0, 0, 0, 0.26);
  .row-reduced {
    color: #2A6336;
    padding: 15px 5px;
    .amount {
      color: #2A6336;
      font-family: Recoleta;
      font-size: 13px;
    }

    .currency {
      color: #2A6336;
      padding-left: 5px;
      font-family: Montserrat;
      font-size: 11px;
    }

  }
}

.day-item-today {
  .day-item-body {
    background-color: #E97834;
    color: #fff;
    .month{
      color: #fff;
    }
  }
  &:hover {
    .day-item-body {
      cursor: default !important;
      background-color: #E97834 !important;
    }
  }
  .inprogress {
    margin-top: 10px;
    font-size: 10px;
  }
}

.row-shopping {
  margin-top: -20px;
}

.sidebar-item {
  cursor: pointer;
}

.account-number {
  font-family: 'ITC Souvenir Std Bold';
  font-weight: bold;
}

.active {
  position: relative;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}

.active::after {
  position: absolute;
  right: -20px;
  top: 0;
  content: "";
  width: 30px;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  z-index: 100;
}

.delivery-date {
  font-family: Recoleta;
  font-style: normal;
  height: 250px;
  background-color: #F2F3ED;
}

.delivery-date-title {
  margin-top: 15px;
  color: #2A6336;
}

.delivery-date-title h3 {
  font-family: Recoleta;
  font-size: 13px;
}

.delivery-date-title h3 > span {
  font-family: Recoleta;
  font-size: 13px;
}

.day-item {
  background-color: transparent;
  height: 150px;
}

.day-item-header {
  padding: 5px 0px;
  height: 35px;
}

.day-item-header img {
  display: initial;
  height: 25px;
}


.day-item-body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 110px;
  background-color: #fff;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.day {
  font-family: 'Montserrat';
  font-size: 12px;
  text-transform: capitalize;
}

.month {
  margin-top: 10px;
  font-family: 'Recoleta';
  font-size: 12px;
  font-weight: bold;
  color: #2A6336;
}

.fees {
  margin-top: 10px;
}

.amount {
  font-family: 'Recoleta';
  font-weight: bold;
  font-size: 12px;
}

.free {
  font-family: Recoleta;
  font-size: 12px;
  font-weight: bold;
  color: #E97834;
}

.currency {
  padding-left: 5px;
  font-family: Montserrat;
  font-size: 10px;
}

.balance{
  line-height: 30px;
}

.balance-label {
  font-family: Montserrat;
  font-size: 11px;
}

.day-item-active {
  .waiting {
    margin-top: 8px;
    font-size: 9px;
    color: #fff;
  }
}

.day-item-active .day-item-body {
  background-color: #2A6336;
}

.day-item-active .day,
.day-item-active .month,
.day-item-active .fees {
  color: #fff;
}

.day-item:hover .day-item-body {
  background-color: #f6faf4;
  cursor: pointer;
}

.day-item-active:hover .day-item-body {
  background-color: #2A6336;
}

.section-cart-products {
  h2 {
    font-size: 18px;
    font-family: Recoleta;
    margin-bottom: 0px;
    color: #D6403F;
  }
  hr {
    background-color: #D6403F;
  }
}

.section-order-products {
  h2 {
    font-size: 18px;
    font-family: Recoleta;
    color: #2A6336;
    margin-bottom: 0px;
  }
  hr {
    background-color: #2A6336;
  }
}

.form-section {
  label {
    line-height: 28px;
  }
}

</style>
