<template>

  <div class="row justify-content-center">
    <div class="col-10 py-3">
      <div class="row">
        <div class="section-title">
          <img :src="require('images/one.svg')" />
          Création de compte
        </div>
        <div class="section-body">
          <div class="form-row">

            <div class="form-group col-md-5 required">
              <label class="control-label" for="ownerFirstName">Prénom(s) et nom</label>
              <input placeholder="votre nom complet" autofocus="autofocus" type="text" 
                v-model="user.ownerFirstName" name="ownerFirstName" 
                class="form-control" :class="validation_class_for('ownerFirstName')" />
              <div class="invalid-feedback">
                {{first_error_on_attribute('ownerFirstName')}}
              </div>
            </div>

            <div class="form-group col-md-5 required">
              <label class="control-label" for="ownerPhone">Téléphone</label>
              <input placeholder="Numéro de téléphone" autofocus="autofocus" type="text" name="ownerPhone" 
                v-model="user.ownerPhone" 
                class="form-control" :class="validation_class_for('ownerPhone')" />
              <div class="invalid-feedback">
                {{first_error_on_attribute('ownerPhone')}}
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-5 required">
              <label class="control-label" for="email">Email</label>
              <input placeholder="Email" autofocus="autofocus" type="email" name="email" 
                v-model="user.email" 
                class="form-control" :class="validation_class_for('email')" />
              <div class="invalid-feedback">
                {{first_error_on_attribute('email')}}
              </div>
            </div>

            <div class="form-group col-md-5 required">
              <label class="control-label" for="password">Mot de passe</label>
              <input placeholder="Mot de passe" autofocus="autofocus" type="password" name="password" 
                v-model="user.password" 
                class="form-control" :class="validation_class_for('password')" />
              <div class="invalid-feedback">
                {{first_error_on_attribute('password')}}
              </div>
            </div>
 
          </div>

          <div class="form-row">
            <div class="form-group col-md-5 required">
              <label class="control-label">Selectionner votre quartier</label>
              <multiselect 
                :allow-empty="false" 
                v-model="selectedZone" 
                track-by="id" 
                label="libelle" 
                :options="zones"
                :show-labels="false"
                :searchable="true"
                placeholder="Chercher votre quartier" ></multiselect>
              <div class="d-block invalid-feedback">
                {{first_error_on_attribute('ZoneId')}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group d-flex justify-content-center">
        <button @click="validateInfo()" type="button" class="btn btn-lg btn-primary" >Valider</button>
      </div>
    </div>
  </div>

</template>

<script>

import services from './packs/services'

export default {
  data: function () {
    return {
      user: {},
      errors: [],
      reference: {label: 'Ami client du Club', value: 'friends'},
      zones: [],
      selectedZone: {}
    }
  },
  async mounted() {
    this.zones = await services.getZones()
  },
  computed: {

    referenceList() {
      return [      
        {label: 'Ami client du Club', value: 'friends'},
        {label: 'Instagram', value: 'instagram'},
        {label: 'Facebook', value: 'facebook'},
        {label: 'Recherche Internet', value: 'internet'},
        {label: 'Autres', value: 'other'}
      ]
    },
  },
  methods: {

    async validateInfo() {
      try {
        this.user.ZoneId = this.selectedZone.id 
        this.user = await services.createUser(this.user)
        this.errors = []
        window.location.href = '/account/complete-signup'
      } catch (err) {
        if(err.response.status == 422) { 
          this.errors = err.response.data
        }
      }  
    },

    validation_class_for(field_name) {
      if(this.errors) {
        let error = this.errors[field_name] 
        return error ? 'is-invalid' : ''
      }
      return ''
    },

    first_error_on_attribute(field_name) {
      if(this.errors && this.errors[field_name]) {
        let firstError = this.errors[field_name][0] 
        return firstError
      }
      return null
    }

  }
}
</script>

<style lang='scss' scoped>

.form-group.required .control-label:after {
  content:"*";
  color:red;
  margin-left: 3px;
}

.form-group .control-label {
  font-family: 'Recoleta';
  font-weight: 500;
  font-size: 14px;
}

</style>
