<template>
  <div v-if="cart" class="container">
    <div class="row p-2">
      <div class="col-12 section-payment">
        <div class="row row-payment border-bottom">
          <div class="col-12">
            Votre solde disponible
            <span class="amount color-red">{{user.balance | formatAmount}}</span>
            <span class="currency color-red">FCFA</span>
          </div>
        </div>
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Total de la commande</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{orderTotal | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Valeur consigne récupérable *</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{glassAmount | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment border-bottom">
          <div class="col-8">
            <span>Frais de livraison</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{orderFees | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment row-recap">
          <div class="col-8">
            <span>Total dû à la livraison</span>
          </div>
          <div class="col-4 text-right">
            <span class="amount">{{amountDue | formatAmount}}</span>
            <span class="currency">FCFA</span>
          </div>
        </div>
        <div class="row row-payment row-recap border-bottom border-top px-3">
          <div class="col-12">
            <h3 class="title t-3">Spécifiez le montant que vous voulez créditer</h3>
            <div class="row">
              <div class="col-12">
                <input type="radio" id="next-delivery" value="next-delivery" @change="onPaymentTypeChanged('nextDelivery')" v-model="paymentMode">
                <label for="next-delivery">Payer la prochaine livraison</label>
                <br>
                <input type="radio" id="manual" value="manual" @change="onPaymentTypeChanged(null)" v-model="paymentMode">
                <label for="manual">Saisir un montant manuellement </label>
                <br>
              </div>
              <div class="container form-section">
                <div class="form-group row">
                  <div class="col-10">
                    <input class="form-control" type="text" v-model="paymentAmount" />
                  </div>
                  <div class="col-2 text-left">
                    <label class="col-form-label currency">FCFA</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-payment row-recap border-bottom px-3">
          <div class="col-12">
            <div class="row">
              <div class="col-12 text-right">
                <div class="container">
                  <div class="row">
                    <div class="col-12 text-right">
                      <span class="fee">Frais</span>
                      <span class="amount" v-if="mode == 'om'">1%</span>
                      <span class="amount" v-if="mode == 'wave'">1%</span>
                      <span class="amount" v-if="mode == 'cb'" >2%</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-right">
                      <span class="fee">Total</span>
                      <span class="amount">{{total | formatAmount}}</span>
                      <span class="currency">FCFA</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-payment row-action">
          <div class="col-12 d-flex justify-content-center align-content-center">
            <button type="button" @click="pay()" class="btn btn-lg btn-secondary ml-2">Payer par {{paymentProvider}}</button>
          </div>
        </div>
      </div>
    </div>

    <form ref="formPayment" target="_parent" ng-cloak action="/online_payment/orange/payment" method="GET" >
      <input type="hidden" id="amount" name="amount" v-model="amountToPay" />
    </form>
  </div>
</template>

<script>
import eventBus from './packs/eventBus'
import service from './packs/services'
import wave from './packs/wave'

export default {
  props: ['userId', 'mode'],
  data: function () {
    return {
      user: null,
      order: null,
      cart: null,
      boxes: [],
      likes: null,
      needValidateDate: false,
      selectedDate: null,
      paymentMode: 'next-delivery',
      paymentProvider: '',
      paymentProviders: {
        'wave': 'WAVE',
        'om': 'Orange Money',
        'cb': 'Carte bancaire'
      },
      paymentAmount: null,
      amountToPay: 0,
      amountToCharge: 0,
      startPayment: false,
      delivery_fee: 0
    }
  },
  async mounted() {
    this.refresh()
  },

  computed: {

    amountDue() {
      if (this.user.balance >= this.orderTTCIncludingGlass) return 0
      return this.orderTTCIncludingGlass - this.user.balance
    },

    orderTTC() {
      return this.orderTotal
    },

    orderTTCIncludingGlass() {
      return this.orderTTC + this.glassAmount
    },

    glassAmount() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.glass_amount
      });
      return total
    },

    orderTotal() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      return total
    },

    orderFees() {
      return this.delivery_fee
    },

    /**
     * Compute the amount total to pay
     */
    total() {
      this.amountToPay = 0
      let amount = parseInt(this.paymentAmount);
      if(this.mode == 'om' && isFinite(amount) && amount > 0) {
        let percentOM = 0.99
        this.amountToPay = Math.ceil(amount / percentOM)
        return this.amountToPay;
      }

      if (this.mode == 'cb' && isFinite(amount) && amount > 0) {
        let percent = 0.98;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }

      if (this.mode == 'wave' && isFinite(amount) && amount > 0) {
        let percent = 0.99;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }
      return this.amountToPay;
    },

  },
  methods: {

    async refresh() {
      this.user = await service.getUser(this.userId)
      this.likes = await service.getLikes()
      this.boxes = await service.getBoxes()
      this.order = await service.getCartContent()
      this.cart = await service.getTCartContent()
      this.delivery_fee = this.order.delivery_fee ? parseInt(this.order.delivery_fee) : 0
      this.paymentMode = 'next-delivery'
      this.onPaymentTypeChanged('nextDelivery')
      this.paymentProvider = this.paymentProviders[this.mode]
    },

    /**
     * Method pay
     */
    async pay(){
      const currentLink = window.location.href;
      if (false) {
        this.$swal({
          icon: 'warning',
          cancelButtonText: 'OK',
          cancelButtonColor: '#2A6336',
          title: "Oups!",
          text: "Le montant à payer en ligne doit etre au minimum de 500 Fcfa",
          showCancelButton: true,
          showConfirmButton: false
        });
        return
      }
      switch(this.mode) {
        case 'om':
          this.$refs.formPayment.submit()
          break
        case 'cb':
          /*
          const orderId = (new Date().getTime()) + '-' + this.userId
          sendPaymentInfos(orderId,
            'TIOSN8424',
            '3jQbQoQr?cbfcwH6VMPtfOGlNtlV-Q$Eh#5Ud5ylJt94ESE^zN',
            'www.clubtiossane.sn', currentLink,
            currentLink, this.amountToPay,
            'dakar', '', '', '', '')
          break
          */
          const cb_payment_ref = (new Date().getTime()) + '-' + this.userId
          const cb_resp = await wave.initNgeniusPayment(this.amountToPay, cb_payment_ref)
          window.location.href = cb_resp.payment_url
          break
        case 'wave':
          const payment_ref = (new Date().getTime()) + '-' + this.userId
          const resp = await wave.makePayment(this.amountToPay, payment_ref)
          window.location.href = resp.payment_url
          break
      }

    },

    async cancelPay(){
      this.startPayment = false
    },

    /**
     * On payment changed
     */
    onPaymentTypeChanged(paymentType) {
      let paymentTypeAmount = {
        debt: () => this.user.balance,
        debtNextDelivery: () => this.amountDue,
        nextDelivery: () => this.amountDue
      };

      this.paymentAmount = paymentTypeAmount[paymentType] ? paymentTypeAmount[paymentType]() : 0
    },

  }
}
</script>

<style lang='scss' scoped>

.product-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

.accordion{
  width: 100%;
  .card {
    height: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
  }
  .card-header {
    position: relative;
    background: #FFFFFF;
    padding: .5rem .75rem;
    button {
      display: flex;
      align-items: center;
      &:after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
        box-sizing: border-box;
      }
      &[aria-expanded="true"]:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
  }

  .card button,
  .card button:hover,
  .card button:active,
  .card button:focus {
    font-family: Recoleta;
    font-size: 14px;
    font-weight: 500;
    color: #D6403F;
    text-decoration: none;
    box-shadow: none;
  }
}

#main {
  overflow: hidden;
  #main-content {
    overflow: auto;
  }
  .overlay {
    display: block;
    background-color: #000;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0.5;
    z-index: 1072;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.section-payment-expanded {
  z-index: 1082;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
}

.row-payment {
  margin-left: 0px;
  margin-right: 0px;
  padding: 15px 25px 15px 15px;
  font-family: Montserrat;
  font-size: 13px;
}

.row-payment > div {
  padding-left: 2px;
  padding-right: 2px;
}

.row-recap {
  background: #F4F3F0;
}

.section-payment {
  background-color: #fff;
  .row-reduced {
    color: #2A6336;
    padding: 15px 5px;
    .amount {
      color: #2A6336;
      font-family: Recoleta;
      font-size: 13px;
    }

    .currency {
      color: #2A6336;
      padding-left: 5px;
      font-family: Montserrat;
      font-size: 11px;
    }

  }
}

.day-item-today {
  .day-item-body {
    background-color: #E97834;
    color: #fff;
    .month{
      color: #fff;
    }
  }
  &:hover {
    .day-item-body {
      cursor: default !important;
      background-color: #E97834 !important;
    }
  }
  .inprogress {
    margin-top: 10px;
    font-size: 10px;
  }
}

.row-shopping {
  margin-top: -20px;
}

.sidebar-item {
  cursor: pointer;
}

.account-number {
  font-family: 'ITC Souvenir Std Bold';
  font-weight: bold;
}

.delivery-date {
  font-family: Recoleta;
  font-style: normal;
  height: 250px;
  background-color: #F2F3ED;
}

.delivery-date-title {
  margin-top: 15px;
  color: #2A6336;
}

.delivery-date-title h3 {
  font-family: Recoleta;
  font-size: 13px;
}

.delivery-date-title h3 > span {
  font-family: Recoleta;
  font-size: 13px;
}

.day-item {
  background-color: transparent;
  height: 150px;
}

.day-item-header {
  padding: 5px 0px;
  height: 35px;
}

.day-item-header img {
  display: initial;
  height: 25px;
}


.day-item-body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 110px;
  background-color: #fff;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 25px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.day {
  font-family: 'Montserrat';
  font-size: 12px;
  text-transform: capitalize;
}

.month {
  margin-top: 10px;
  font-family: 'Recoleta';
  font-size: 12px;
  font-weight: bold;
  color: #2A6336;
}

.fees {
  margin-top: 10px;
}

.amount {
  font-family: 'Recoleta';
  font-weight: bold;
  font-size: 12px;
}

.free {
  font-family: Recoleta;
  font-size: 12px;
  font-weight: bold;
  color: #E97834;
}

.currency {
  padding-left: 5px;
  font-family: Montserrat;
  font-size: 10px;
}

.balance{
  line-height: 30px;
}

.balance-label {
  font-family: Montserrat;
  font-size: 11px;
}

.day-item-active {
  .waiting {
    margin-top: 8px;
    font-size: 9px;
    color: #fff;
  }
}

.day-item-active .day-item-body {
  background-color: #2A6336;
}

.day-item-active .day,
.day-item-active .month,
.day-item-active .fees {
  color: #fff;
}

.day-item:hover .day-item-body {
  background-color: #f6faf4;
  cursor: pointer;
}

.day-item-active:hover .day-item-body {
  background-color: #2A6336;
}

.section-cart-products {
  h2 {
    font-size: 18px;
    font-family: Recoleta;
    margin-bottom: 0px;
    color: #D6403F;
  }
  hr {
    background-color: #D6403F;
  }
}

.section-order-products {
  h2 {
    font-size: 18px;
    font-family: Recoleta;
    color: #2A6336;
    margin-bottom: 0px;
  }
  hr {
    background-color: #2A6336;
  }
}

.form-section {
  label {
    line-height: 28px;
  }
}

.btn-group-vertical {
  width: 100%;
}
</style>
