<template>
  <div class="container">
    <div class="row px-4" v-if="order">
      <div class="col-12 pt-5">
        <div class="row">
          <a class="link-2" @click="goBack()">< Retour</a>
        </div>
        <div class="row pt-3">
          <h3 class="title">{{ order.date | formatDate }}</h3>
        </div>
      </div>
      <div class="col-12 pt-3">
        <div class="row table-amount">
          <table class="table table-borderless">
            <tbody>
              <tr v-if="isOrder" class="border-bottom">
                <td class="col-label" width="70%">Total de la commande</td>
                <td class="col-value" width="20%">
                  {{ orderTotal | formatAmount }}
                </td>
                <td class="col-value" width="10%">FCFA</td>
              </tr>
              <tr v-if="isOrder" class="border-bottom">
                <td class="col-label" width="70%">Valeur consignes livrées</td>
                <td class="col-value" width="20%">
                  {{ glassAmount | formatAmount }}
                </td>
                <td class="col-value" width="10%">FCFA</td>
              </tr>
              <tr v-if="isOrder" class="border-bottom">
                <td class="col-label" width="70%">Valeur consignes rendues</td>
                <td class="col-value" width="20%">
                  {{ returnGlassAmount | formatAmount }}
                </td>
                <td class="col-value" width="10%">FCFA</td>
              </tr>
              <tr v-if="isOrder" class="border-bottom">
                <td class="col-label" width="70%">Frais de livraison</td>
                <td class="col-value" width="20%">
                  {{ orderFee | formatAmount }}
                </td>
                <td class="col-value" width="10%">FCFA</td>
              </tr>
              <tr>
                <td class="col-label" width="70%">{{ totalAmountLabel }}</td>
                <td class="col-value" width="20%">
                  {{ amountPaid | formatAmount }}
                </td>
                <td class="col-value" width="10%">FCFA</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="isOrder">
          <h3 class="title t-1">Détail de ma commande:</h3>
          <table class="table table-product table-borderless">
            <thead>
              <tr class="border-bottom">
                <td width="50%"></td>
                <td width="15%">Commandé</td>
                <td width="15%">Livré</td>
                <td width="20%">Prix</td>
              </tr>
            </thead>
          </table>
          <table
            class="table table-product table-borderless"
            v-for="category in usedCategories"
            v-bind:key="category.id"
          >
            <thead>
              <tr>
                <th colspan="3">{{ category.name }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="border-bottom"
                v-for="orderItem in categoryOrderItems(category.id)"
                v-bind:key="orderItem.id"
              >
                <td width="50%">{{ orderItem.product.name }}</td>
                <td width="15%">
                  <span class="input-qte">{{ orderItem.qty_stock }}</span>
                </td>
                <td width="15%">
                  <span class="input-qte">{{
                    orderItem.quantityDelivered
                  }}</span>
                </td>
                <td width="20%">{{ orderItem.price }} FCFA</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "./packs/services";

export default {
  props: ["orderId"],
  data: function () {
    return {
      order: null,
      categories: [],
      totalAmountLabel: "",
    };
  },
  async mounted() {
    let order_id = this.orderId || this.$route.params.orderId;
    this.categories = await service.getCategories();
    this.order = await service.getOrderById(order_id);
    this.totalAmountLabel =
      this.order.status == "online"
        ? "Montant du paiement en ligne"
        : "Montant payé";
  },
  computed: {
    isOrder() {
      return this.order.status != "online";
    },

    orderTotal() {
      let paymentAmount = 0;
      if (this.order.status == "online") paymentAmount = this.order.paymentAmt;
      this.order.order_items.forEach((item) => {
        paymentAmount +=
          (item.quantityDelivered * item.price) / item.multiplier;
      });
      return paymentAmount;
    },

    glassAmount() {
      let total = 0;
      this.order.order_items.forEach((item) => {
        total += item.glass_amount_delivered;
      });
      return total;
    },

    returnGlassAmount() {
      let total = (this.order.returnedBottle || 0) * 1500;
      total += (this.order.returnedPot || 0) * 500;
      return total;
    },

    orderFee() {
      return this.order.delivery_fee ? parseInt(this.order.delivery_fee) : 0;
    },

    amountPaid() {
      return (
        this.order.paymentAmt + this.order.debtRepayment + this.order.advance
      );
    },

    usedCategories() {
      return this.categories.filter(
        (c) => this.categoryOrderItems(c.id).length > 0
      );
    },
  },
  methods: {
    categoryOrderItems(categoryId) {
      return this.order.order_items.filter(
        (o) => o.product.category.id == categoryId
      );
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.table-amount {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  padding: 10px 15px;
  margin-bottom: 40px;
}

.table-amount .table {
  margin-bottom: 0px;
}

.table-amount td.col-label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding-left: 0px;
}
.table-amount td.col-value {
  font-family: "ITC Souvenir Std Demi";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  padding-left: 0px;
}

.table-product th {
  font-family: "ITC Souvenir Std Demi";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-left: 0px;
}

.table-product td {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding-left: 0px;
}

.input-qte {
  width: 35px;
  height: 35px;
  float: left;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 8px;
  color: #757575;
  padding: 8px 0px;
  text-align: center;
  border: 1px solid #ced4da;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}
</style>
