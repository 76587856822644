<template>
  <nav :class="`${show ? 'open' : 'closed'} sub-nav`">
    <button
      @click="close"
    >
      <i class="fas fa-chevron-left"></i>
      <h2>{{ heading }}</h2>
    </button>
    <ul>
      <li v-for="cat in categories" :key="cat.title">
        <a :href="`/category/${cat.id}`">{{ cat.title }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['categories', 'heading', 'show'],
  methods: {
    close() {
      this.$emit('close-subnav');
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-nav {
  padding-top: 1rem;
  top: 3rem;
}

ul {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
  list-style: inside;
  margin: 45px 0 0;
  width: 100%;
}

li {
  padding: 1rem;
  position: relative;

  a:after {
    background: transparent;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

li:first-child {
  border-top: 1px solid rgba(0,0,0,0.2);
}

a {
  align-items: center;
  color: var(--primary-color);
  display: inline-flex;
}

button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  display: flex;
  left: 0;
  margin: 0;
  padding: 1rem;
  position: absolute;
  text-align: left;
  top: 4px;
  width: 100%;

  svg {
    margin-right: 0.5rem;
  }

  h2 {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 0 1rem;
  }
}
</style>
