<template>
  <div class="container">
    <div class="row">
      <div class="col-12 pt-5">
        <h2 class="section-title title-1 text-center">
          Historique
        </h2>
        <table class="table table-borderless">
          <thead>
            <tr>
              <th>Date :</th>
              <th colspan="2">Type d'opération :</th>
            </tr>
          </thead>
          <tbody>
            <tr @click="goOrderHistoryDetail(order.id)" v-for="order in orders" v-bind:key="order.id">
              <td width="50%">{{order.createdAt | formatDate}}</td>
              <td width="40%">{{orderType(order)}}</td>
              <td class="table-actions" width="10%">
                <span style="font-size: 1.2em; color: #BDBDBD;">
                  <i class="fas fa-chevron-right"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import service from './packs/services'

export default {
  data: function () {
    return {
      orders: []
    }
  },
  async mounted() {
    this.orders = await service.getOrders()
  },
  computed: {
  },
  methods: {

    orderType(order) {
      return order.status=='online' ? 'Paiement en ligne' : 'Livraison'
    },

    goOrderHistoryDetail(orderId) {
      this.$router.push({name: 'orders-history-detail', params: {orderId}})
    }
  }
}
</script>

<style scoped>

th {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

td {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
}
</style>
