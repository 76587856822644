<template>
  <div v-if="cart" class="cart">
    <div class="cart-icon" :class="shakeEffect"
      @click="toggleCart()">
      <img :src="require('images/cart.svg')" />
      <span v-show="cartCount > 0" class="badge badge-pill badge-warning">{{cartCount}}</span>
      <span v-show="order && cart && cartCount == 0 && orderTotal > 0" class="badge-ok" style="font-size: 1.0em; color: #2A6336;">
        <i class="fas fa-check-circle"></i>
      </span>
      <div class="text-center" v-if="cart && cartCount > 0">
        <span class="amount color-red">{{cartTotal | formatIntAmount}}</span>
        <span class="currency color-red">FCFA</span>
      </div>
      <div class="text-center" v-if="order && cart && cartCount == 0 && orderTotal > 0">
        <span class="amount color-primary">{{orderTotal | formatIntAmount}}</span>
      </div>
      <div class="text-center orderDate" v-if="order && cart && cartCount == 0 && orderTotal > 0">
        <span class="color-primary">{{order.date | formatMonth}}</span>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div v-show="showContent" class="cart-content">
        <div class="row cart-items">
          <div class="col-auto">
            <cart-box-item v-for="item in cartBoxes" :key="item.id" :item="item" />
            <cart-item v-for="item in cartProducts" :key="item.id" :item="item" :with-likes="authenticated" :likes="likes" />
          </div>
        </div>
        <div class="row cart-total border-top border-bottom">
          <span class="amount">{{cartTotal | formatIntAmount}}</span>
          <span class="currency">FCFA</span>
        </div>
        <div class="row cart-action mx-5">
          <a href="/my-next-delivery" class="btn btn-primary btn-lg btn-block">Continuer</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import services from './packs/services'
import eventBus from './packs/eventBus'

export default {
  data: function () {
    return {
      shakeEffect: "",
      items: [],
      likes: [],
      cart: null,
      order: null,
      showContent: false,
      authenticated: false,
      loading: false
    }
  },
  mounted() {
    this.refresh()
    eventBus.$on('OrderNeedValidation', function() {
      this.refresh()
      this.shake()
    }.bind(this))

    eventBus.$on('OrderValidated', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('OrderUpdated', function() {
      this.refresh()
    }.bind(this))
  },

  computed: {

    unValidated() {
      return this.unValidatedProducts.length > 0
    },

    unValidatedProducts() {
      return this.cart.order_items
    },

    cartProducts() {
      return this.items.filter(item => !item.panier)
    },

    cartBoxes() {
      return this.items.filter(item => item.panier)
    },

    cartCount() {
      return this.items.length
    },

    cartTotal() {
      let total = 0
      this.items.forEach(item => {
        let price = 0
        if (item.panier) {
          price = item.box.price
        }else {
          price = item.product.price
        }
        total += item.quantity * price
      });
      return total
    },

    orderTotal() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      return total
    },
  },

  methods: {

    toggleCart() {
      if(this.authenticated){
        if (this.unValidated) {
          window.location.href = '/my-cart'
        } else {
          window.location.href = '/my-next-delivery'
        }
      }else {
        window.location.href = '/cart/recap'
      }
    },

    async refresh() {
      this.loading = true
      let user = await services.getCurrentUser()
      if(user.id) {
        this.authenticated = true
        this.order = await services.getCartContent()
      }

      this.cart = await services.getTCartContent()
      this.likes = await services.getLikes()
      this.items = this.cart.order_items
      this.loading = false
    },

    shake() {
      this.shakeEffect = "shake-hard shake-constant"
      setTimeout(() => {
        this.shakeEffect = ""
      }, 500)
    },
  }
}
</script>

<style lang='scss' scoped>

.orderDate {
  margin-top: 3px;
  line-height: 12px !important;
  border-top: solid 1px #2A6336;
  span{
    font-size: 8px;
  }
}
.cart {
  position: relative;
}

.cart-icon {
  cursor: pointer;
  width: 30px;
  padding-top: 10px;
}

.cart-icon .text-center {
  line-height: 5px;
}

.cart-icon img {

  width: 30px;
}

.cart-content {
  padding: 10px 10px;
  position: absolute;
  top: 65px;
  right: -15px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color:#F4F3F0;
  z-index: 100;
}

.cart-items {
  height: 60%;
  overflow-y: auto;
}

.cart-action {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 10%;
}

.cart-total {
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: bold;
  height: 10%;
  .amount {
    font-size: 18px;
  }
  .currency {
    font-size: 18px;
    margin-left: 2px;
  }
}

.badge-ok {
  padding: 0px;
  position: absolute;
  right: -10px;
  top: 3px;
}

.badge {
  padding: 0;
  position: absolute;
  right: -10px;
  top: 3px;
  font-size: 10px;
  font-weight: 400;
  font-family: Montserrat;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.badge-warning {
  color: #FFF;
  background-color: #E97834;
}

.card {
  width: auto;
  height: 102px;
}

.card-body {
  padding: 10px 10px;
}

.card-body-title {
  height: 60px;
}

.card-title {
  margin-bottom: 5px;
}

.card-text-price {
  font-size: 10px;
}

.cart-item {
  margin-bottom: 10px;
  background-color:#fff;
}

.cart-item img {
  width: 100%;
  height: auto;
  min-height: 100px;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}

.amount {
  font-family: 'Montserrat';
  font-size: 8px;
}

.currency {
  font-family: 'Montserrat';
  font-size: 5px;
}


</style>
