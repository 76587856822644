<template>
  <div>
    <portal to="mobile-nav-open">
      <button class="btn btn-light d-lg-none" type="button"
        @click="open()"
      >
        <span style="font-size: 1.7em; color: var(--primary-color);">
          <i class="fas fa-bars"></i>
        </span>
      </button>
      <div class="btn-menu-title">Menu</div>
    </portal>

    <div v-if="show" class="backdrop" @click="close()"/>

    <nav :class="show ? 'open' : 'closed'">
      <button
        aria-label="Close"
        class="close"
        @click="close()"
      >
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L21 20.9483" stroke="currentColor"/>
          <path d="M21 1L0.999998 20.9483" stroke="currentColor"/>
        </svg>
      </button>
      <ul class="menu">
        <li class="nav-item">
          <a class="nav-link" href="/" role="button">
            <span class="ic-md">
              <i class="fas fa-home"></i>
            </span>
            Accueil
          </a>
        </li>
        <li v-for="r in rubriques" class="nav-item" :key="r.id">
          <a
            class="nav-link foobar"
            role="button"
            :id="`navbarDropdown-${r.id}`"
            @click="openSubnav(r.published_client_categories, r.title)"
          >
            <span>{{ r.title }}</span>
            <i class="fas fa-chevron-right align-right"></i>
          </a>
        </li>
      </ul>
      <div class="footer mt-3">
        <ul class="navbar-nav navbar-footer">
          <li class="nav-item">
            <a class="nav-link" href="/faq">
              <span>FAQ</span>
            </a>
            <!--
            TODO: Get "about"/"a propos" link
            <a class="nav-link" href="">
              <span>A Propos</span>
            </a>
            -->
            <a class="nav-link" href="/contact">
              <span>Contact</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <mobile-subnav
      :heading="subnavHeading"
      :categories="categories"
      :show="showSubnav"
      @close-subnav="closeSubnav()"
    ></mobile-subnav>
  </div>
</template>

<script>
import MobileSubnav from './Subnav'

export default {
  components: {
    'mobile-subnav': MobileSubnav,
  },
  props: ['current', 'rubriques'],
  data() {
    return {
      categories: [],
      show: false,
      showSubnav: false,
      showSubnavPrev: false,
      subnavHeading: '',
    }
  },
  mounted() {
    if (!this.current) return

    const currentRubrique = this.rubriques.find(({ id }) => id === this.current)
    this.categories = currentRubrique.published_client_categories
    this.subnavHeading = currentRubrique.title
    this.showSubnavPrev = true
  },
  methods: {
    close() {
      this.show = false;
      this.showSubnavPrev = this.showSubnav;
      this.showSubnav = false;
    },
    open() {
      this.show = true;
      this.showSubnav = this.showSubnavPrev;
    },
    openSubnav(categories, heading) {
      this.categories = categories;
      this.subnavHeading = heading;
      this.showSubnav = true;
    },
    closeSubnav() {
      this.showSubnav = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.backdrop {
  background-color: rgba(34, 34, 34, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

nav {
  background-color: var(--gray);
  bottom: 0;
  color: var(--primary-color);
  display: block;
  left: 0;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(-100%);
  transition: visibility .2s ease-in-out, transform .2s ease-in-out;
  visibility: hidden;
  width: 80%;

  &.open {
    transform: translateX(0);
    visibility: visible;
  }
}

.menu {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - 170px);
  list-style: none;
  margin: 45px 0 0;
  width: 100%;
}

.close {
  color: var(--primary-color);
  left: 1rem;
  position: absolute;
  top: 1rem;
}

.nav-item:first-child {
  margin-top: 5px;
}

.nav-item:not(:first-child) {
  border-top: 1px solid rgba(0,0,0,0.2);
}

.nav-link {
  align-items: center;
  color: var(--primary-color);
  display: inline-flex;
  font-size: 1.1em;
  padding: 1rem;
  width: 100%;

  svg {
    margin-left: auto;
  }
}

.footer {
  background-color: var(--brand-orange);
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  position: absolute;
  width: 100%;

  .nav-link {
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
</style>
