<template>
  <div class="row p-4">
    <div class="col-12 title-1 text-center">
      Gestion des livraisons
    </div>

    <div class="col-10 offset-1" >
      <div class="row form-group mb-2 mt-3">
        <div class="col-12 form-label">Décaler ma date de prochaine livraison jusqu’au:</div>
        <div class="col-12">
          <multiselect 
            :allow-empty="false" 
            v-model="newDeliveryDate" 
            track-by="value" 
            label="label" 
            :options="possibleDeliveryDate"
            :show-labels="false"
            :searchable="false" ></multiselect>
        </div>
      </div>

      <div class="row form-group mt-2">
        <div class="col-12 form-label">Rythme des livraisons:</div>
        <div class="col-12">
          <multiselect 
            :allow-empty="false" 
            v-model="deliveryStatus" 
            track-by="value" 
            label="label" 
            :options="deliveryStatuses"
            :show-labels="false"
            :searchable="false" ></multiselect>
        </div>
      </div>

      <div class="row form-group mt-2">
        <div class="col-8 offset-2">
          <button @click="updateSettings()" class="btn btn-lg btn-block btn-primary margin-top-30">Sauvegarder</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import zoneHelper from './packs/zone_helper'
import service from './packs/services'
import EventBus from './packs/eventBus'
import moment from 'moment'

export default {
  props: ["userId"],
  data: function () {
    return {
      user: null,
      newDeliveryDate: null,
      deliveryStatus: null
    }
  },
  async mounted() {
    this.user = await service.getUser(this.userId)
    let cart = await service.getCartContent()
    this.deliveryStatus = this.deliveryStatuses.find(delivery => delivery.value === this.user.deliveryStatus)
    let currentDeliveryDate = moment(cart.date)
    this.newDeliveryDate = {
      value: currentDeliveryDate.toDate(),
      label: currentDeliveryDate.format('dddd, DD MMMM YYYY')
    }
  },
  computed: {
    possibleDeliveryDate: function () {
      if (!this.user || !this.user.zone.day) {
        return [];
      }
      let dates = []
      let startDate = moment().isoWeekday(zoneHelper.dayToNumValue(this.user.zone.day))
      for (var i = 0; i < 52; i++){
        let currentDate = startDate.add(1, 'week')
        dates.push({
          value: currentDate.toDate(),
          label: currentDate.format('dddd, DD MMMM YYYY')
        })
      }
      return dates;
    },

    deliveryStatuses: function () {
      return [{
        value: "weekly",
        label: "Toutes les semaines"
      }, {
        value: "biweekly",
        label: "Toutes les deux semaines"
      }]
    }
  },
  methods: {

    async updateSettings() {
      await service.updateDeliverySettings(this.user.id, this.deliveryStatus.value, this.newDeliveryDate.value)
      EventBus.$emit('DeliveryDateUpdated', {})
    },
  }
}
</script>

<style scoped>
.form-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 35px;
}

</style>
