<template>
  <div class="d-flex justify-content-center mt-4">
    <div class="col-12 section-cart-products">
      <h2 class="section-title title-1">
        Produits Favoris
      </h2>
      <div v-if="products.length > 0" class="col-md-12 col-xl-12 px-0 mt-4">
        <product-list v-bind:item_ids="products"></product-list>
      </div>
    </div>
  </div>
</template>

<script>
import service from './packs/services'

export default {
  data: function () {
    return {
      products: []
    }
  },
  async mounted() {
    let likes = await service.getLikes();
    this.products = likes.filter(l => l.liked)
        .map(l => ({item_id: l.Product_id, item_type: 'product'}));
  }
}
</script>

<style scoped>

.section-title {
  text-align: center;
  color: #2A6336;
}

hr {
  background-color: #2A6336;
}

</style>
