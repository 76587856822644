<template>
  <div v-if="product" class="container product-detail">

    <div class="row">
      <div class="col-12 mt-15">
        <div class="main-img" :style="{backgroundImage: 'url(' + currentImage + ')' }">
          <ribbon :padding="{left: '15px'}" v-if="product.active_tags.new_article" />
        </div>
      </div>
    </div>
    <div class="row margin-top-10">
      <div class="col-12">
        <img
          v-for="(img, index) in product.images"
          :key="`img-${index}`" :src="img"
          class="product-th-image thumbnail float-left"
          @click="selectImage(img)" />
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-10">
              <h2><span class="badge badge-warning">{{product.category.name}}</span></h2>
            </div>
            <div class="col-2">
              <a v-if="!temporary" @click="changeLike()">
                <span class="ic-md" v-show="liked" style="font-size: 1.5em; color: #D6403F;">
                  <i class="fas fa-heart"></i>
                </span>
                <span class="ic-md" v-show="!liked" style="font-size: 1.5em; color: #D6403F;">
                  <i class="far fa-heart"></i>
                </span>
              </a>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <h1>{{product.name}}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12 price">
              {{product.price | formatIntAmount}} FCFA
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <p>{{product.description}}</p>
            </div>
          </div>
          <div class="row mt-2 order" v-if="product.orderable">
            <div class="col-12">
              <button @click="add()" class="btn btn-primary" v-if="is_add_state" >Ajouter</button>
              <div class="justify-content-around col-12 px-0 product-qte" v-if="is_qte_state">
                <div class="d-flex align-center">
                  <button @click="minusOne()" class="btn btn-qte input-qte-minus">-</button>
                  <input :disabled="true" v-model="quantity" class="form-control input-qte" type="text" />
                  <button @click="plusOne()" class="btn btn-qte">+</button>
                </div>
                <product-frequency
                  v-if="!product.isOneShotable && is_qte_state"
                  :productId="product.id"
                  :oneShot="oneShot"
                  :inCart="true"
                  :isBox="false"
                ></product-frequency>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion" id="product-info" v-if="has_product_info">
      <div class="accordion-item" v-if="product.producer">
        <div class="accordion-header" id="producer-header">
          <span class="btn btn-block text-left"
                data-toggle="collapse"
                data-target="#producer-content"
                aria-expanded="true"
                aria-controls="producer-content">
            Le Producteur
          </span>
        </div>
        <div id="producer-content" class="accordion-body collapse show" aria-labelledby="producer-header">
          <div class="titles">
            <div class="title big-title">{{ product.producer.name }}</div>
            <div class="sub-title" v-if="product.producer.location">{{product.producer.location}}</div>
          </div>
          <img :src="getProducerImage" v-if="getProducerImage" alt="Producer image" />
          <p>{{product.producer.description}}</p>
        </div>
      </div>
      <div class="accordion-item" v-if="product.conservation">
        <div class="accordion-header" id="conservation-header">
          <span class="btn btn-block text-left" data-toggle="collapse" data-target="#conservation-content" aria-controls="conservation-content" :aria-expanded="!product.producer">
            Conservation
          </span>
        </div>
        <div id="conservation-content" aria-labelledby="conservation-header" :class="['accordion-body collapse', (!product.producer ? 'show' : '')]">
          <div class="titles">
            <div class="title">{{ product.conservation.title }}</div>
          </div>
          <img :src="product.conservation.image" v-if="product.conservation.image" alt="Conservation image" />
          <p>{{product.conservation.description}}</p>
        </div>
      </div>
      <div class="accordion-item" v-if="product.ingredients">
        <div class="accordion-header" id="-ingredients-header">
          <span class="btn btn-block text-left" data-toggle="collapse" data-target="#ingredients-content" aria-controls="ingredients-content" :aria-expanded="!product.producer && !product.conservation">
            Ingredients
          </span>
        </div>
        <div id="ingredients-content" aria-labelledby="ingredients-header" :class="['accordion-body collapse', (!product.producer && !product.conservation ? 'show' : '')]">
          <div class="titles">
            <div class="title">{{ product.ingredients.title }}</div>
          </div>
          <img :src="product.ingredients.image" v-if="product.ingredients.image" alt="Conservation image" />
          <p>{{product.ingredients.description}}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import EventBus from './packs/eventBus'
import service from './packs/services'

export default {
  props: ["productId"],
  data: function () {
    return {
      status: 'add',
      currentImage: '',
      quantity: 0,
      cart: null,
      product: null,
      liked: false,
      temporary: true
    }
  },
  async mounted() {
    this.product = await service.getProduct(this.productId)
    this.cart = await service.getTCartContent()
    let likes = await service.getLikes()
    if(likes) {
      this.liked = true
    }

    this.currentImage = `https://www.clubtiossane.sn/ckossam/${this.product.imgUrl}`
    this.temporary = this.cart.temporary
    if(this.cart.order_items) {
      let ordered = this.cart.order_items.find(p => p.product.id === this.product.id)
      if(ordered) {
        this.quantity = ordered.quantity
      }
    }
    this.status = (this.quantity > 0) ? 'set_quantity' : 'add'
  },
  computed: {
    is_add_state(){
      return this.status === 'add'
    },

    is_qte_state(){
      return this.status === 'set_quantity'
    },

    getProducerImage() {
      const path = this.product.producer.imageUrl;
      return path ? 'https://www.clubtiossane.sn/ckossam/' + path : null;
    },

    has_product_info() {
      return this.product.producer || this.product.conservation || this.product.ingredients
    },

    oneShot() {
      if (!this.cart.order_items) return false

      const orderItem = this.cart.order_items.find(p => p.product?.id === this.product.id)
      if (!orderItem) return

      return orderItem.oneShot
    }
  },
  methods: {

    async add(){
      this.status = 'set_quantity'
      await this.plusOne()
    },

    selectImage(img) {
      this.currentImage = img
    },

    async changeLike() {
      this.liked = !this.liked;
      await service.setLike(this.product.id, this.liked)
    },

    async plusOne() {
      this.quantity++;
      await service.setCartQuantity(this.product.id, this.quantity)
      EventBus.$emit("OrderNeedValidation", {})
    },

    async minusOne() {
      if(this.quantity > 0) {
        this.quantity--;
        await service.setCartQuantity(this.product.id, this.quantity)
        EventBus.$emit("OrderNeedValidation", {})
        if(this.quantity === 0){
          this.status = 'add'
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>

$chevron-down: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba(42, 99, 54, 1)'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
.product-detail .card {
  width: 100%;
  margin-top: 10px;
  border-radius: 2px;
  padding: 5px 0;
}

.product-detail .badge {
  font-family: Montserrat,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 7px 10px;
  text-transform: uppercase;
  color: #fff;
  background: #E97834;
  border-radius: 5px;
}

.product-detail h1 {
  font-family: Recoleta,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.product-detail .card-body p {
  font-family: Montserrat,serif;
  font-style: normal;
  font-size: 14px;
}

.product-detail {
  .mt-15 {
    margin-top: 15px;
  }
  .main-img {
    width: 100%;
    height: 30vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .label {
    font-family: Recoleta,serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 5px;
  }
  .value {
    font-family: Recoleta,serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .price {
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.product-detail .thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 5px;
}

#product-info {
  margin-bottom: 1.5em;
  border-top: 1px;
  border-color: #bdbdbd;
  border-style: solid;
  .accordion-item {
    border-bottom: 1px;
    border-style: solid;
    border-color: #bdbdbd;
  }
  .accordion-header {
    font-family: 'ITC Souvenir',serif;
    padding: .75rem 0;
    span {
      padding-left: 0;
      font-weight: bold;
      font-size: 18px;
    }
    span:after {
      content: '';
      float: right;
      width: 1.25rem;
      height: 1.25rem;
      margin-top: 0.25rem;
      background-image: $chevron-down;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      transition: transform .2s ease-in-out;
    }
    span[aria-expanded='true']:after {
      transform: rotate(-180deg);
    }
  }

  .accordion-body {
    font-family: Montserrat,serif;
    font-style: normal;
    img {
      width: 100%;
    }
    .titles {
      margin-bottom: 0.8rem;
      .title {
        font-size: 1.1em;
      }
      .big-title {
        font-weight: bold;
        font-size: 1.2em;
      }
    }
    p {
      font-size: 14px;
      margin-top: 0.75rem;
    }
  }
}

.product-th-image {
  cursor: pointer;
}

.product-qte {
  margin-top: 5px;
  display: inline-flex;
}

.btn-qte {
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  line-height: 10px;
  font-family: Recoleta,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #2A6336;
  border: 1px solid #2A6336;
  border-radius: 4px;
}

.input-qte:disabled, .input-qte[readonly] {
  background-color: #fff;
}

.input-qte {
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  font-family: Montserrat,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #757575;
  padding: 8px 0;
  text-align: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.input-qte-minus {
  padding-top: 6px;
}

.card-text-price {
  margin-bottom: 0;
}

.select {
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 8px;
  height: 35px;
  font-family: Montserrat,serif;
  font-size: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.select.active {
  color: #fff;
  background-color: #2A6336;
}

</style>
