<template>
<div v-if="cart" class="container-fluid px-0 pt-5">

  <div v-if="message" class="alert alert-warning">{{ message }}</div>

  <div v-if="todayOrder" class="row justify-content-center">
    <div @click="gotoHistoryOrder()" class="today-order">
      <div class="title">
        Commande en cours de livraison
      </div>
      <div class="action">
        Voir détail <i class="fa fa-angle-down"></i>
      </div>
    </div>
  </div>

  <div class="row p-4 form-section">
    <div v-if="unValidated" class="col-12 text-ftm-1 text-center color-red">
      Produits non-confirmés dans votre panier
    </div>
  </div>

  <div v-if="cart && cart.order_items.length > 0" class="row p-2 justify-content-center">
    <div class="accordion" id="accordionUnvalidated">
      <div class="card">
        <div class="card-header section-cart-products" id="headingUnvalidated">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseUnvalidated" aria-expanded="true" aria-controls="collapseUnvalidated">
              Voir les produits non-confirmés
            </button>
          </h2>
        </div>

        <div id="collapseUnvalidated" class="collapse show" aria-labelledby="headingUnvalidated" data-parent="#accordionUnvalidated">
          <div class="card-body">
            <div class="d-flex flex-col flex-wrap justify-content-around">
              <div class="product-container" v-for="item in cartBoxes" v-bind:key="item.id">
                <ro-cart-box-item :key="item.id" :box="item.box" :quantity="item.quantity" :oneShot="item.oneShot" />
              </div>
              <div class="product-container"
                v-for="item in cartProducts"
                v-bind:key="item.id"
              >
                <ro-cart-item :item="item" :likes="likes" />
              </div>
            </div>

            <div class="row p-2 justify-content-center">
              <button @click="addToOrder()" class="btn btn-md btn-primary mr-2">Ajouter à la commande</button>
              <a href="/rubrique/12" class="btn btn-md btn-secondary">Continuer vos courses</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-4 form-section">
    <div v-if="empty" class="col-12 text-ftm-1 text-center">
      Aucune livraison future programmée
    </div>
    <div v-if="orderValidated" class="col-12 text-ftm-1 text-center">
      Livraison confirmée pour le {{order.date | formatFriendlyDateFull}} - {{orderTotal | formatAmount}} FCFA <i class="fas fa-check"></i>
    </div>
  </div>

  <div class="row p-2 justify-content-center">
    <a v-if="empty" href="/rubrique/12" class="btn btn-lg btn-primary">Faites vos courses</a>
  </div>

  <div v-if="order && order.order_items.length > 0" class="row p-2 justify-content-center">
    <div class="accordion" id="accordionValidated">
      <div class="card">
        <div class="card-header section-cart-products" id="headingValidated">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left color-primary" type="button" data-toggle="collapse" data-target="#collapseValidated" aria-expanded="true" aria-controls="collapseValidated">
              Voir / Modifier mon panier
              <i class="fas fa-check-circle"></i>
            </button>
          </h2>
        </div>

        <div id="collapseValidated" class="collapse show" aria-labelledby="headingValidated" data-parent="#accordionValidated">
          <div class="card-body">
            <ro-order-item-list :boxes="orderBoxes" v-bind:items="orderProducts"></ro-order-item-list>
            <div class="row p-2 justify-content-center">
              <button @click="editOrder()" class="btn btn-md btn-primary mr-2">Modifier commande</button>
              <a href="/rubrique/12"  class="btn btn-md btn-secondary">Continuer vos courses</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div v-if="orderValidated" class="col-10 form-section">
      <div class="form-row">
        <div class="form-group col-12 required">
          <textarea placeholder="Une indication pour cette livraison, un horaire à respecter, un contact à appeler, etc."
            v-model="orderComment" name="orderComment" class="form-input size-1" />
        </div>
      </div>
      <div class="form-row justify-content-center">
        <div class="form-group d-flex">
          <button @click="addOrderComment()" type="button" class="btn btn-primary btn-lg" >Envoyer</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-2 justify-content-center">
    <div class="col-12 mb-2 ml-2">
      <span>Solde disponible : {{user.balance | formatAmount}} FCFA</span>
    </div>
    <div class="btn-group-vertical">
      <a href="/account/payment/wave" class="btn btn-outline-success text-left"><span class="fa fa-angle-right float-right f-btn"></span>Recharger solde par WAVE</a>
      <a href="/account/payment/om" class="btn btn-outline-success text-left"><i class="fa fa-angle-right float-right f-btn"></i>Recharger solde par OM</a>
      <a href="/account/payment/cb" class="btn btn-outline-success text-left"><i class="fa fa-angle-right float-right f-btn"></i>Recharger solde par Carte bancaire</a>
    </div>
  </div>

</div>
</template>

<script>
import service from './packs/services'
import viewHelper from './packs/zone_helper'
import EventBus from './packs/eventBus'
import wave from './packs/wave'
import moment from 'moment'
export default {
  props: ['userId'],
  data: function () {
    return {
      selectedMenu: 'NEXT-DELIVERY',
      slickSettings: {
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false
      },
      zone: null,
      cart: null,
      boxes: [],
      todayOrder: null,
      user: null,
      order: null,
      likes: [],
      deliveryDates:[],
      selectedDate: null,
      deliveryFees: [],
      needValidateDate: false,
      paymentMode: 'next-delivery',
      paymentProvider: {
        value: 'WAVE',
        label: "WAVE"
      },
      paymentType: {
        value: 'cash',
        label: 'Payer à la livraison'
      },
      paymentAmount: null,
      amountToPay: 0,
      startPayment: false,
      paymentExpanded: false,
      delivery_fee: 0,
      orderComment: null,
      glassBalance: null,
      message: null
    }
  },

  async mounted() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.message = null
    if (urlParams.has('payment-success')) {
      this.message = 'Votre solde sera mis à jour dans quelques instants. Veuillez actualiser la page'
    }
    await this.init()

    EventBus.$on('DeliveryDateUpdated', function() {
      this.init()
    }.bind(this))

    EventBus.$on('OrderNeedValidation', function() {
      this.init()
    }.bind(this))

    EventBus.$on('OrderUpdated', function() {
      this.init()
    }.bind(this))

    EventBus.$on('ProductDeleted', function() {
      this.loadCart()
    }.bind(this))
  },

  computed: {

    cartProducts() {
      return this.cart.order_items.filter(item => !item.panier)
    },

    cartBoxes() {
      return this.cart.order_items.filter(item => item.panier)
    },

    orderProducts() {
      return this.order.order_items.filter(item => item.type === 'product')
    },

    /** init boxes */
    orderBoxes() {

      let orderedBoxes = []
      this.boxes.forEach(box => {
        let occurrence = this.order.order_items.find(oi => oi.type === 'box' && oi.box_id == box.id)
        if(occurrence) {
          let productBox = box.box_items.find(b => occurrence.product.id == b.productId)
          box.quantity = occurrence.quantity / productBox.quantity
          box.oneShot = occurrence.oneShot
          orderedBoxes.push(box)
        }
      });
      return orderedBoxes
    },

    isToday() {
      return this.deliveryDates.length > 0 && this.deliveryDates[0].today
    },

    needValidation() {
      if (this.unValidated) {
        return true
      }

      return this.needValidateDate
    },

    unValidated() {
      return this.unValidatedProducts.length > 0
    },

    unValidatedProducts() {
      return this.cart.order_items
    },

    amountDue() {
      if (this.user.balance >= this.orderTTC) return 0
      return this.orderTTC - this.user.balance
    },

    orderTTC() {

      return this.orderTotal + this.orderFees
    },

    orderTotal() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      return total
    },

    todayOrderTotal() {
      let total = 0
      this.todayOrder.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      let fee = this.todayOrder.delivery_fee ? parseInt(this.todayOrder.delivery_fee) : 0
      return total + fee
    },

    orderFees() {
      return this.delivery_fee
    },

    cartTotal() {
      if(this.validated) {
        return 0
      }
      let total = 0
      this.cart.order_items.forEach(item => {
        let price = 0
        if (item.panier) {
          price = item.box.price
        }else {
          price = item.product.price
        }
        total += item.quantity * price
      });
      return total
    },

    /**
     * if no order and cartempty
     */
    empty() {
      if (this.order.order_items.length == 0 && this.cart.order_items.length == 0) {
        return true
      }
      return false
    },

    /**
     * if there is an order
     */
    orderValidated() {
      if (this.order && this.order.order_items.length > 0) {
        return true
      }
      return false
    },

    paymentTypes() {
      return [
        {
          value: 'WAVE',
          label: "Wave"
        }, {
          value: 'OM',
          label: "Orange Money"
        }, {
          value: 'CB',
          label: "Carte bancaire"
        }]
    },

    /**
     * Compute the amount total to pay
     */
    total() {
      this.amountToPay = 0
      let amount = parseInt(this.paymentAmount);
      if(this.paymentProvider.value == 'OM' && isFinite(amount) && amount > 0) {
        let percentOM = 0.99
        this.amountToPay = Math.ceil(amount / percentOM)
        return this.amountToPay;
      }

      if (this.paymentProvider.value == 'CB' && isFinite(amount) && amount > 0) {
        let percent = 0.98;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }

      if (this.paymentProvider.value == 'WAVE' && isFinite(amount) && amount > 0) {
        let percent = 0.99;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }
      return this.amountToPay;
    },
  },

  methods: {

    async loadCart() {
      this.order = await service.getCartContent()
      this.delivery_fee = this.order.delivery_fee ? parseInt(this.order.delivery_fee) : 0
    },

    async init() {
      this.deliveryDates = []
      this.todayOrder = await service.getTodayOrder()
      this.glassBalance = await service.getGlassBalance()
      this.user = await service.getUser(this.userId)
      this.likes = await service.getLikes()
      this.boxes = await service.getBoxes()
      this.zone = this.user.zone
      await this.loadCart()
      this.cart = await service.getTCartContent()
      this.deliveryFees = await service.getDeliveryFees()
      this.orderComment = this.order.comment
      let currentDate = moment(this.order.date).toDate()
      if(this.todayOrder && this.todayOrder.id > 0) {
        this.deliveryDates.push({
          index: 0,
          date: moment(this.todayOrder.date).toDate(),
          selected: false,
          today: true,
          changed: false
        })
      }
      for (let i = 1; i < 45; i++) {
        // we remove all Sundays
        let momentDate = moment().add(i, 'days')
        if (i === 1 && momentDate.hour() >= 23) {
          continue
        }
        let isSaturdayCustomer = (this.zone.day === 'Saturday')
        if((isSaturdayCustomer && momentDate.isoWeekday() === 6) || (!isSaturdayCustomer && momentDate.isoWeekday() !== 7)){
          let itemDate = {
            index: i,
            date: momentDate.toDate(),
            selected: false,
            free: false,
            changed: false,
            deliveryFee: viewHelper.getDayfee(this.deliveryFees, momentDate.locale('en').format('dddd'))
          }
          if(momentDate.isSame(currentDate, 'day')) {
            itemDate.selected = true
            this.selectedDate = itemDate
            if(this.deliveryDates.length > 0 && !this.deliveryDates[0].today) {
              this.slickSettings.initialSlide = this.deliveryDates.length - 1
            }
          }
          if(momentDate.isoWeekday() == viewHelper.dayToNumValue(this.zone.day)){
            itemDate.free = true
            itemDate.deliveryFee = 0
          }
          this.deliveryDates.push(itemDate)
        }
      }
    },

    isSelected(menuItem) {
      return this.selectedMenu == menuItem;
    },

    active(menuItem) {
      return this.isSelected(menuItem) ? 'active' : ''
    },

    selectMenu(menuItem) {
      this.selectedMenu = menuItem;
    },

    async updateDate(updatedDate) {
      if(updatedDate.today) {
        return false;
      }
      if(updatedDate && this.selectedDate && moment(updatedDate.date).isSame(this.selectedDate.date, 'day')) {
        return false;
      }

      if(this.selectedDate) {
        this.selectedDate.selected = false
        this.selectedDate.changed = false
      }
      this.selectedDate = updatedDate
      this.selectedDate.selected = true
      this.selectedDate.changed = true
      this.needValidateDate = true
      if(this.selectedDate.free) {
        this.delivery_fee = 0
      }else {
        this.delivery_fee = parseInt(this.selectedDate.deliveryFee)
      }
    },

    showPayment() {
      this.paymentMode = 'next-delivery'
      this.onPaymentTypeChanged('nextDelivery')
      this.startPayment = true
    },

    /**
     * Expand payment block
     */
    expand() {
      this.paymentExpanded = true
    },

    expandWithPayment() {
      this.paymentExpanded = true
      this.showPayment()
    },

    /**
     * Collapse payment block
     */
    collapse() {
      this.paymentExpanded = false;
    },

    gotoHistoryOrder() {
      window.location.href = '/delivery-history/'+this.todayOrder.id
    },

    /**
     * Method validate
     */
    async validate(){
      try {
        if(this.needValidateDate) {
          await service.updateDeliveryDate(this.user.id, this.selectedDate.date, this.delivery_fee)
          this.needValidateDate = false
        }
        if(this.unValidated) {
          let result = await service.validateCart()
          this.order = result
        }
        EventBus.$emit("OrderValidated", {})
        await this.init()
      } catch (err) {
        console.log(err)
        if(err.response.status == 422) {

          if (err.response.data.code == 'ERR21H') {
            this.$swal({
              icon: 'warning',
              cancelButtonText: 'OK',
              cancelButtonColor: '#2A6336',
              title: "Oups!",
              text: "Vous ne pouvez pas être livré(e) ce jour car votre panier contient un produit non livrable sous 24h. Veuillez choisir une autre date ou retirer ce(s) article(s) de votre panier.",
              showCancelButton: true,
              showConfirmButton: false
            });
          }
          if (err.response.data.code == 'ERR_BLOCKED_WEEK') {
            this.$swal({
              icon: 'warning',
              cancelButtonText: 'OK',
              cancelButtonColor: '#2A6336',
              title: "🎉 Fête de la Korite ! 🎉",
              html: "<p style=\"text-align: left;\">Nous faisons une petite pause, svp choisissez une autre date et on se charge du reste.</p>",
              showCancelButton: true,
              showConfirmButton: false
            });
          }
        }
      }

    },

    /**
     * Method pay
     */
    async pay(){
      const currentLink = window.location.href;
      if (this.amountToPay < 500) {
        this.$swal({
          icon: 'warning',
          cancelButtonText: 'OK',
          cancelButtonColor: '#2A6336',
          title: "Oups!",
          text: "Le montant à payer en ligne doit etre au minimum de 500 Fcfa",
          showCancelButton: true,
          showConfirmButton: false
        });
        return
      }
      switch(this.paymentProvider.value) {
        case 'OM':
          this.$refs.formPayment.submit()
          break
        case 'CB':
          const orderId = (new Date().getTime()) + '-' + this.userId
          sendPaymentInfos(orderId,
            'TIOSN8424',
            '3jQbQoQr?cbfcwH6VMPtfOGlNtlV-Q$Eh#5Ud5ylJt94ESE^zN',
            'www.clubtiossane.sn', currentLink,
            currentLink, this.amountToPay,
            'dakar', '', '', '', '')
          break
        case 'WAVE':
          const payment_ref = (new Date().getTime()) + '-' + this.userId
          const resp = await wave.makePayment(this.amountToPay, payment_ref)
          window.location.href = resp.payment_url
          break
      }

    },

    async cancelPay(){
      this.startPayment = false
    },

    /**
     * On payment changed
     */
    onPaymentTypeChanged(paymentType) {
      let paymentTypeAmount = {
        debt: () => this.user.balance,
        debtNextDelivery: () => this.amountDue,
        nextDelivery: () => this.amountDue
      };

      this.paymentAmount = paymentTypeAmount[paymentType] ? paymentTypeAmount[paymentType]() : 0
    },

    /**
     * Go to edit order
     */
     editOrder() {
      window.location.href = '/my-cart'
    },

    /**
     * Confirm le cart et navigue vers update space
     */
    async addToOrder() {
      window.location.href = '/my-cart'
    },

    async addOrderComment(){
      await service.addOrderComment(this.orderComment)
      this.$swal({
        icon: "success",
        cancelButtonText: "OK",
        cancelButtonColor: "#2A6336",
        title: "Envoyé",
        text: "Message envoyé",
        showCancelButton: true,
        showConfirmButton: false,
      });
    },

  }
}
</script>

<style lang='scss' scoped>

.product-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

.accordion{
  width: 100%;
  .card {
    height: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
  }
  .card-header {
    position: relative;
    background: #FFFFFF;
    padding: .5rem .75rem;
    button {
      display: flex;
      align-items: center;
      &:after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
        box-sizing: border-box;
      }
      &[aria-expanded="true"]:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
  }

  .card button.btn-block,
  .card button.btn-block:hover,
  .card button.btn-block:active,
  .card button.btn-block:focus {
    font-family: Recoleta;
    font-size: 14px;
    font-weight: 500;
    color: #D6403F;
    text-decoration: none;
    box-shadow: none;
  }
}

#main {
  overflow: hidden;
  #main-content {
    overflow: auto;
  }
  .overlay {
    display: block;
    background-color: #000;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0.5;
    z-index: 1072;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.section-payment-expanded {
  z-index: 1082;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
}

.row-payment {
  margin-left: 0px;
  margin-right: 0px;
  padding: 15px 25px 15px 15px;
  font-family: Montserrat;
  font-size: 13px;
}

.row-payment > div {
  padding-left: 2px;
  padding-right: 2px;
}

.row-recap {
  background: #F4F3F0;
}

.section-payment {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.16), 0 -1px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.16), 0 -1px 5px 0 rgba(0, 0, 0, 0.26);
  .row-reduced {
    color: #2A6336;
    padding: 15px 5px;
    .amount {
      color: #2A6336;
      font-family: Recoleta;
      font-size: 13px;
    }

    .currency {
      color: #2A6336;
      padding-left: 5px;
      font-family: Montserrat;
      font-size: 11px;
    }

  }
}

.day-item-today {
  .day-item-body {
    background-color: #E97834;
    color: #fff;
    .month{
      color: #fff;
    }
  }
  &:hover {
    .day-item-body {
      cursor: default !important;
      background-color: #E97834 !important;
    }
  }
  .inprogress {
    margin-top: 10px;
    font-size: 10px;
  }
}

.row-shopping {
  margin-top: -20px;
}

.sidebar-item {
  cursor: pointer;
}

.account-number {
  font-family: 'ITC Souvenir Std Bold';
  font-weight: bold;
}

.active {
  position: relative;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}

.active::after {
  position: absolute;
  right: -20px;
  top: 0;
  content: "";
  width: 30px;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  z-index: 100;
}

.delivery-date {
  font-family: Recoleta;
  font-style: normal;
  height: 250px;
  background-color: #F2F3ED;
}

.delivery-date-title {
  margin-top: 15px;
  color: #2A6336;
}

.delivery-date-title h3 {
  font-family: Recoleta;
  font-size: 13px;
}

.delivery-date-title h3 > span {
  font-family: Recoleta;
  font-size: 13px;
}

.day-item {
  background-color: transparent;
  height: 150px;
}

.day-item-header {
  padding: 5px 0px;
  height: 35px;
}

.day-item-header img {
  display: initial;
  height: 25px;
}


.day-item-body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 110px;
  background-color: #fff;
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 25px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.day {
  font-family: 'Montserrat';
  font-size: 12px;
  text-transform: capitalize;
}

.month {
  margin-top: 10px;
  font-family: 'Recoleta';
  font-size: 12px;
  font-weight: bold;
  color: #2A6336;
}

.fees {
  margin-top: 10px;
}

.amount {
  font-family: 'Recoleta';
  font-weight: bold;
  font-size: 12px;
}

.free {
  font-family: Recoleta;
  font-size: 12px;
  font-weight: bold;
  color: #E97834;
}

.currency {
  padding-left: 5px;
  font-family: Montserrat;
  font-size: 10px;
}

.balance{
  line-height: 30px;
}

.balance-label {
  font-family: Montserrat;
  font-size: 11px;
}

.day-item-active {
  .waiting {
    margin-top: 8px;
    font-size: 9px;
    color: #fff;
  }
}

.day-item-active .day-item-body {
  background-color: #2A6336;
}

.day-item-active .day,
.day-item-active .month,
.day-item-active .fees {
  color: #fff;
}

.day-item:hover .day-item-body {
  background-color: #f6faf4;
  cursor: pointer;
}

.day-item-active:hover .day-item-body {
  background-color: #2A6336;
}

.section-cart-products {
  h2 {
    font-size: 18px;
    font-family: Recoleta;
    margin-bottom: 0px;
    color: #D6403F;
  }
  hr {
    background-color: #D6403F;
  }
}

.section-order-products {
  h2 {
    font-size: 18px;
    font-family: Recoleta;
    color: #2A6336;
    margin-bottom: 0px;
  }
  hr {
    background-color: #2A6336;
  }
}

.section-verre{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.form-section {
  label {
    line-height: 28px;
  }
}

.f-btn {
  height: 25px;
}

.btn-group-vertical {
  width: 350px;
}

.today-order {
  background-color: #e97834;
  border-radius: 12px;
  width: 80%px;
  padding: 10px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  .title {
    font-family: 'Montserrat SemiBold';
    font-size: 15px;
  }
  .action {
    margin-top: 5px;
    font-family: 'Montserrat';
    font-size: 12px;
  }
}

</style>
